import React from 'react';

export default function Customs() {
  return (
    <section
      id="mem"
      className="bg-white pb-[32px] md:pb-[24px] lg:pb-[50px] mt-[120px] xl:mt-[105px]"
    >
      <div className="bg-[#CFEDD3] py-16 md:pt-[132px] md:pb-[161px] relative md:h-[413px]">
        <div className="px-8 mx-auto text-center xl:w-[693px] md:px-0 md:w-[42%]">
          <legend className="font-bold text-center xl:mx-[-84px] md:pr-[39px] xl:text-[60px] md:text-[38px] text-[#484352] xl:leading-[90px] md:leading-[60px]">
            Shipping Guide for International Customers
          </legend>
        </div>
        <img
          src="/images/pricing-offer-hand.png"
          alt="Vitract - Pricing"
          className="absolute left-0 top-[-32px] hidden md:block"
        />
        <img
          src="/images/courier-parcel.png"
          alt="Courier - Parcel"
          className="absolute right-0 top-[16px] hidden md:block"
        />
      </div>

      <div className="mt-8 md:mt-6 lg:mt-[106px] max-w-[100vw] w-[1440px] md:max-w-[81%] flex flex-col md:justify-center md:items-center gap-y-[20px] md:gap-x-[20px] md:gap-y-0 px-4 md:px-0 mx-[auto]">
        <div className="flex lg:flex-row flex-col gap-8 md:gap-10 lg:gap-[130px] xl:w-full lg:w-full justify-center items-center lg:justify-between lg:items-start">
          <p className="xl:w-[572px] lg:w-[450px] h-[full] text-2xl md:w-[670px] leading-[32.4px]">
            All customers shipping their samples back to our lab must fill out a
            customs declaration form. We advise that you use Canada post (or any
            other reliable shipper) for shipping your samples. Depending on
            where you are shipping from, it may take 1 to 3 weeks for your
            sample to arrive at our lab. <br /> <br />
            Please follow the instructions below carefully. We will use the
            international shipping procedure by Canada Post as an example. This
            should also apply to other courier services. <br /> <br />
            <strong>Step 1.</strong> Fill out your custom form found {` `}
            <a
              href="https://www.canadapost-postescanada.ca/information/app/cdc?execution=e1s1"
              className="text-[#FF713F] hover:underline underline-offset-4"
            >
              here
            </a>
            . Follow the illustration on the left side of this page to complete
            the form.
            <br /> <br />
            <strong>Step 2.</strong> Submit your form and a barcode is
            automatically created for you.
            <br /> <br />
            <strong>Step 3.</strong> Either print or display this barcode on
            your mobile device at the post office when you are dropping off your
            package for shipment. If you have any questions, please contact us
            at hello@vitract.com <br /> <br />
          </p>

          <div className="md:w-[500px] lg:w-auto lg:h-auto sticky right-0 max-h-[992px] h-[80vh] overflow-auto">
            <img
              src="/images/custom-form.svg"
              alt="Custom Form Guide"
              className="mx-auto"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
